import React from "react";
import {connect} from "react-redux";
import {checkIfUserHasRole} from "../../../utils/NewRolesUtils";
import _ from "lodash";
import {Col} from "react-bootstrap";
import ALink from "../../../components/basics/alink/ALink";

export class SettingChooserItem extends React.Component {

    getChildren() {
        const {children, farm} = this.props;
        let c = children.map((child, index) => {
            let hasRole = true;
            if (child.minRole) {
                let rolesToCheck = _.isArray(child.minRole) ? child.minRole : [child.minRole];
                for (let i = 0; i < rolesToCheck.length; i++) {
                    if (!checkIfUserHasRole(rolesToCheck[i], farm)) {
                        hasRole = false;
                    }
                }
            }
            if (hasRole && child.shouldShow) {
                hasRole = child.shouldShow();
            }
            if (hasRole) {
                return (
                    <li key={index} id={child.id}>
                        <ALink href={`/${child.path}`}>{child.name}</ALink>
                    </li>
                )
            }
        });
        c = c.filter(item => item);
        return c;
    }

    render() {
        const {header} = this.props;
        let children = this.getChildren();
        if (children.length === 0) return null;
        return (
            <Col xl={4} lg={6} md={12} className={"pb-4"}>
                <div className={"menu-header text-nowrap"}>
                    <h5>{header}</h5>
                    <ul>
                        {children}
                    </ul>
                </div>
            </Col>
        )
    }
}

SettingChooserItem = connect(state => ({
    farm: state.location.farm
}))(SettingChooserItem);

export default SettingChooserItem;
