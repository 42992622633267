import React from "react";
import {connect} from "react-redux";
import SettingChooserItem from "./SettingChooserItem";
import {Row} from "react-bootstrap";
import Card from "../../../components/basics/card/Card";
import * as RoleTypes from "validators-schema/Api/constants/roleTypes";
import {withTranslation} from "react-i18next";

export class SettingChooser extends React.Component {

    render() {
        const {t, farm, user: {ClientID}} = this.props;
        let settingList = [
            {
                header: t("profile"),
                children: [
                    {
                        path: `${farm}/settings/profile/general`,
                        name: t("general"),
                    },
                    {
                        path: `${farm}/settings/profile/changePassword`,
                        name: t("changePassword"),
                    },
                    {
                        path: `${farm}/settings/profile/changeMQTTPassword`,
                        name: t("changeMQTTPassword"),
                    },
                    {
                        path: `${farm}/settings/profile/personalize`,
                        name: t("personalize"),
                    }
                ]
            },
            {
                header: t("buildings"),
                children: [
                    {
                        path: `${farm}/settings/buildings/manage`,
                        name: t("manage"),
                        minRole: RoleTypes.BUILDING,
                        id: "buildings-manage",
                    },
                    {
                        path: `${farm}/settings/buildings/map`,
                        name: t("map"),
                        minRole: RoleTypes.SERVICE
                    },
                    {
                        path: `${farm}/settings/buildings/pairDevice`,
                        name: t("pairDevice"),
                        minRole: RoleTypes.SERVICE
                    },
                    {
                        path: `${farm}/settings/buildings/assignChambers`,
                        name: t("assignChambers"),
                        minRole: RoleTypes.SERVICE
                    },
                    {
                        path: `${farm}/settings/buildings/assignRFIDToStand`,
                        name: t("assignRFIDToStand"),
                        minRole: RoleTypes.SERVICE
                    }
                ]
            },
            {
                header: t("dictionary"),
                children: [
                    {
                        name: t("manage"),
                        path: `${farm}/settings/dictionary/manage`,
                        minRole: RoleTypes.BREEDING,
                        id: "dictionary-manage"
                    },
                    {
                        path: `${farm}/settings/dictionary/medicines`,
                        name: t("medicines"),
                        minRole: RoleTypes.BREEDING,
                        id: "medicines"
                    }
                ]
            },
            {
                header: t("devices"),
                children: [
                    {
                        path: `${farm}/settings/devices/add`,
                        name: t("add"),
                        minRole: RoleTypes.SERVICE,
                    },
                    {
                        path: `${farm}/settings/devices/manage`,
                        name: t("manage"),
                        minRole: RoleTypes.SERVICE,
                    },
                    {
                        path: `${farm}/settings/devices/addressing`,
                        name: t("addressing"),
                        minRole: RoleTypes.SERVICE
                    },
                    {
                        path: `${farm}/settings/devices/subaddressing`,
                        name: t("subaddressing"),
                        minRole: RoleTypes.SERVICE,
                    },
                    {
                        path: `${farm}/settings/devices/openSSH`,
                        name: t("openSSH"),
                        minRole: RoleTypes.SERVICE,
                    }
                ]
            },
            {
                header: t("climate"),
                children: [
                    {
                        path: `${farm}/settings/climate/curves`,
                        name: t("climateCurves"),
                        minRole: [RoleTypes.DEVICE_CONFIG],
                        id: 'climat-curves',
                    },
                    {
                        path: `${farm}/settings/climate/manage`,
                        name: t("settings.settings"),
                        minRole: RoleTypes.DEVICE_CONFIG

                    },
                    {
                        path: `${farm}/settings/climate-sk3/curves`,
                        name: `${t("climateCurves")} SK3`,
                        minRole: [RoleTypes.SERVICE]
                    },
                    {
                        path: `${farm}/settings/climate-sk3/manage`,
                        name: `${t("settings.settings")} SK3`,
                        minRole: [RoleTypes.SERVICE]
                    }
                ]
            },
            {
                header: t("cage"),
                children: [
                    {
                        path: `${farm}/settings/cage/manage`,
                        name: t("settings.settings"),
                        minRole: RoleTypes.DEVICE_CONFIG
                    },
                    {
                        path: `${farm}/settings/cage/display`,
                        name: t("display"),
                        minRole: RoleTypes.DEVICE_CONFIG
                    }
                ]
            },
            {
                header: t("system"),
                children: [
                    {
                        path: `${farm}/settings/system/locale`,
                        name: t("locale"),
                        minRole: RoleTypes.BILLING
                    },
                    {
                        path: `${farm}/settings/system/devices`,
                        name: t("devices"),
                        minRole: RoleTypes.BILLING
                    },
                    {
                        path: `${farm}/settings/system/schedules`,
                        name: t("newSettings.headers.system.schedules"),
                        minRole: RoleTypes.BILLING
                    }
                ]
            },
            {
                header: t("breeding"),
                children: [
                    {
                        path: `${farm}/settings/breeding/cycleSettings`,
                        name: t("cycleSettings"),
                        minRole: RoleTypes.BREEDING
                    },
                    {
                        path: `${farm}/settings/breeding/graftingProgram`,
                        name: t("graftingProgram"),
                        minRole: RoleTypes.BREEDING
                    },
                    {
                        path: `${farm}/settings/breeding/feedingLevels`,
                        name: t("feedingLevels"),
                        minRole: RoleTypes.BREEDING
                    }
                ]
            },
            {
                header: t("feeding"),
                children: [
                    {
                        path: `${farm}/settings/feeding/general`,
                        name: t("newSettings.headers.feeding.general"),
                        id: "general-feeding",
                        minRole: RoleTypes.DEVICE_CONFIG
                    },
                    {
                        path: `${farm}/settings/feeding/f1/manage`,
                        name: t("f1"),
                        id: 'f1-settings',
                        minRole: RoleTypes.DEVICE_CONFIG
                    },
                    {
                        path: `${farm}/settings/feeding/f3f5/manage`,
                        name: t("f3f5"),
                        id: 'f3-f5-settings',
                        minRole: RoleTypes.DEVICE_CONFIG
                    },
                    {
                        path: `${farm}/settings/feeding/curves`,
                        name: t("feedingCurves"),
                        id: 'feeding-curves',
                        minRole: [RoleTypes.DEVICE_CONFIG]
                    },
                    {
                        path: `${farm}/settings/feeding/schedules`,
                        name: t("schedules"),
                        id: 'plans',
                        minRole: [RoleTypes.DEVICE_CONFIG]
                    },
                    {
                        path: `${farm}/settings/feeding/forages`,
                        name: t("forages"),
                        id: 'forages',
                        minRole: [RoleTypes.DEVICE_CONFIG]
                    },
                    {
                        path: `${farm}/settings/feeding/calibration`,
                        name: t("calibration"),
                        id: 'calibration',
                        minRole: RoleTypes.DEVICE_CONFIG
                    },
                    {
                        path: `${farm}/settings/feeding/waterCalibration`,
                        name: t("waterCalibration"),
                        id: 'waterCalibration',
                        minRole: RoleTypes.SERVICE
                    }
                ]
            },
            {
                header: t("weightPanels"),
                children: [
                    {
                        path: `${farm}/settings/weightPanels/manage`,
                        name: t("settings.settings"),
                        minRole: RoleTypes.SERVICE,
                    }
                ]
            },
            {
                header: t("notifications.notifications"),
                children: [
                    {
                        path: `${farm}/settings/notifications/general`,
                        name: t("general")
                    },
                    {
                        path: `${farm}/settings/notifications/cage`,
                        name: t("cage")
                    },
                    {
                        path: `${farm}/settings/notifications/climateSettings`,
                        name: t("climateSettings")
                    },
                    {
                        path: `${farm}/settings/notifications/siloses`,
                        name: t("siloses")
                    },
                    {
                        path: `${farm}/settings/notifications/feeding`,
                        name: t("feeding")
                    },
                    {
                        path: `${farm}/settings/notifications/overallEvents`,
                        name: t("overallEvents")
                    }
                ]
            },
            {
                header: t("newSettings.headers.translate.title"),
                children: [
                    {
                        path: `${farm}/settings/translate/edit`,
                        name: t("translate"),
                        shouldShow: () => ClientID === "TestNewRole", // pokazywanie tylko dla TestNewRole
                        minRole: RoleTypes.TRANSLATION
                    }
                ]
            },
            {
                header: t("debug"),
                children: [
                    {
                        path: `${farm}/settings/debug/shadows`,
                        name: t("shadows"),
                        minRole: RoleTypes.SERVICE
                    },
                    {
                        path: `${farm}/settings/debug/cage`,
                        name: t("newSettings.headers.debug.cage"),
                        minRole: RoleTypes.SERVICE
                    },
                    {
                        path: `${farm}/settings/debug/bridge`,
                        name: t("bridge"),
                        minRole: RoleTypes.SERVICE
                    },
                    {
                        path: `${farm}/settings/debug/nrf`,
                        name: t("nrf"),
                        minRole: RoleTypes.SERVICE
                    },
                    {
                        path: `${farm}/settings/debug/queueSuccess`,
                        name: t("queueSuccess"),
                        minRole: RoleTypes.SERVICE
                    },
                    {
                        path: `${farm}/settings/debug/terminal`,
                        name: t("terminal"),
                        minRole: RoleTypes.SERVICE
                    },
                    {
                        path: `${farm}/settings/debug/programs`,
                        name: t("programs"),
                        minRole: RoleTypes.SERVICE
                    },
                    {
                        path: `${farm}/settings/debug/ramToFram`,
                        name: t("ramToFram"),
                        minRole: RoleTypes.SERVICE
                    },
                    {
                        path: `${farm}/settings/debug/currentPrograms`,
                        name: t("currentPrograms"),
                        minRole: RoleTypes.SERVICE
                    },
                    {
                        path: `${farm}/settings/debug/climate`,
                        name: t("newSettings.headers.debug.climate"),
                        minRole: RoleTypes.SERVICE
                    },
                    {
                        path: `${farm}/settings/debug/loki`,
                        name: t("loki"),
                        minRole: RoleTypes.SERVICE
                    }
                ]
            },
            {
                header: "Aplikacja mobilna", // nie tlumacze tego bo i tak wyleci po testach apki
                children: [
                    {
                        path: `${farm}/mobile?breeding=true`,
                        name: "Uruchom (wszystko)",
                        minRole: RoleTypes.SERVICE
                    },
                    {
                        path: `${farm}/mobile?breeding=false`,
                        name: "Uruchom (urządzenia)",
                        minRole: RoleTypes.SERVICE
                    }
                ]
            },
            {
                header: t("utility"),
                children: [
                    {
                        path: `${farm}/settings/utility`,
                        name: t("utility")
                    }
                ]
            },
            {
                header: t("chainFeeding"),
                children: [
                    {
                        path: `${farm}/settings/chainFeeding/manage`,
                        name: t("settings.settings"),
                        minRole: RoleTypes.SERVICE
                    }
                ]
            },
            {
                header: t("service"),
                children: [
                    {
                        path: `${farm}/settings/service/maintenance`,
                        name: t("settings.setMaintenance"),
                        minRole: RoleTypes.SERVICE
                    },
                    {
                        path: `${farm}/settings/service/deleteFarmData`,
                        name: t("settings.deleteFarmData"),
                        minRole: RoleTypes.SERVICE
                    }
                ]
            }
        ];
        // tymczasowe obejście dla demo
        if (process.env.REACT_APP_STAGE === "demo") {
            for (const set of settingList) {
                if (set.header === "Aplikacja mobilna") {
                    set.children = set.children.map((c) => {
                        delete c.minRole;
                        return c;
                    })
                }
            }
        }
        return (
            <Card>
                <Row>
                    {
                        settingList.map(item => (
                            <SettingChooserItem {...item} />
                        ))
                    }
                </Row>

            </Card>
        )
    }

}

SettingChooser = connect(state => ({
    farm: state.location.farm,
    user: state.user.user
}))(SettingChooser);
export default withTranslation()(SettingChooser);
